import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';


// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyCaeJymgOLj7Fq0PWRyUF-zn9edLU_D1rA",
  authDomain: "auth.hanch.net",
  projectId: "hanch-net",
  storageBucket: "hanch-net.appspot.com",
  messagingSenderId: "604170388779",
  appId: "1:604170388779:web:e97959295725b2d1b8716e",
  measurementId: "G-MW4LQ2LK1E"
});

// Initialize Firebase Authentication
const auth = getAuth();
const dbHanchUsers = getFirestore(app, 'hanchusers');
const dbSongContainers = getFirestore(app, 'songcontainers');
const storage = getStorage(app);
const functions = getFunctions(app); // Initialize Firebase Functions
const messaging = getMessaging(app);


export { auth, dbHanchUsers, dbSongContainers, functions, storage, messaging };