export function initializeStarfield(canvas) {
  const gl = canvas.getContext('webgl');

  let originX = 0;
  let originY = 0;
  let lastTime = 0;

  function resizeCanvas() {
    const dpr = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * dpr;
    canvas.height = canvas.clientHeight * dpr;
    gl.viewport(0, 0, canvas.width, canvas.height);
  }

  window.addEventListener('resize', resizeCanvas);

  function updateOrigin(event) {
    const rect = canvas.getBoundingClientRect();
    const x = -(((event.clientX - rect.left) / rect.width) * 2 - 1);
    const y = -(((rect.height - (event.clientY - rect.top)) / rect.height) * 2 - 1);
    originX = x;
    originY = y;
  }

  canvas.addEventListener('mousedown', (event) => {
    updateOrigin(event);
    canvas.addEventListener('mousemove', updateOrigin);
  });

  canvas.addEventListener('mouseup', () => {
    canvas.removeEventListener('mousemove', updateOrigin);
  });

  canvas.addEventListener('touchstart', (event) => {
    updateOrigin(event.touches[0]);
    canvas.addEventListener('touchmove', (e) => updateOrigin(e.touches[0]));
  });

  canvas.addEventListener('touchend', () => {
    canvas.removeEventListener('touchmove', (e) => updateOrigin(e.touches[0]));
  });

  const vertexShaderSource = `
    attribute vec3 a_position;
    attribute float a_size;
    attribute float a_alpha;
    varying float v_size;
    varying float v_alpha;
    uniform float u_aspectRatio;
    uniform vec2 u_origin;
    void main() {
      vec3 pos = a_position;
      pos.xy += u_origin;
      pos.z += 1.0;
      float scale = 1.0 / pos.z;
      gl_PointSize = a_size * scale;
      v_size = a_size * scale;
      v_alpha = a_alpha;
      gl_Position = vec4(pos.x * scale, pos.y * scale * u_aspectRatio, pos.z * scale, 1.0);
    }
  `;

  const fragmentShaderSource = `
    precision mediump float;
    varying float v_size;
    varying float v_alpha;
    void main() {
      float dist = length(gl_PointCoord - vec2(0.5, 0.5));
      if (dist < 0.5) {
        gl_FragColor = vec4(1.0, 1.0, 1.0, v_alpha);
      } else {
        discard;
      }
    }
  `;

  function createShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.error('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
    }
    return shader;
  }

  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

  function createProgram(gl, vertexShader, fragmentShader) {
    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error('Unable to initialize the shader program: ' + gl.getProgramInfoLog(program));
      return null;
    }
    return program;
  }

  const program = createProgram(gl, vertexShader, fragmentShader);
  if (!program) {
    console.error('Failed to create program');
    return;
  }

  const positionAttributeLocation = gl.getAttribLocation(program, 'a_position');
  const sizeAttributeLocation = gl.getAttribLocation(program, 'a_size');
  const alphaAttributeLocation = gl.getAttribLocation(program, 'a_alpha');
  const u_aspectRatioLocation = gl.getUniformLocation(program, 'u_aspectRatio');
  const u_originLocation = gl.getUniformLocation(program, 'u_origin');

  const starCount = 2000;
  const positions = new Float32Array(starCount * 3);
  const sizes = new Float32Array(starCount);
  const alphas = new Float32Array(starCount);
  const speeds = new Float32Array(starCount);

  function initializeStar(index, fadeIn = true) {
    positions[index * 3] = (Math.random() * 4 - 2) * 1.5;
    positions[index * 3 + 1] = (Math.random() * 4 - 2) * 1.5;
    positions[index * 3 + 2] = Math.random() * 2 + 0.1;
    sizes[index] = Math.random() * 1 + 5;
    alphas[index] = fadeIn ? 0 : 1; // Start with 0 alpha for fade-in effect, or 1 for preloaded stars
    speeds[index] = Math.random() * 0.5 + 0.5;
  }

  for (let i = 0; i < starCount; ++i) {
    initializeStar(i, false); // Initialize all stars as fully visible
  }

  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, positions, gl.DYNAMIC_DRAW);

  const sizeBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, sizeBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, sizes, gl.STATIC_DRAW);

  const alphaBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, alphaBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, alphas, gl.DYNAMIC_DRAW);

  function updatePositions(deltaTime, fadeInNewStars = true) {
    for (let i = 0; i < starCount; ++i) {
      positions[i * 3 + 2] -= speeds[i] * deltaTime;

      // Fade in new stars, but only if fadeInNewStars is true
      if (fadeInNewStars && alphas[i] < 1) {
        alphas[i] = Math.min(alphas[i] + deltaTime, 1);
      }

      if (positions[i * 3 + 2] < -1) {
        initializeStar(i, fadeInNewStars);
        positions[i * 3 + 2] = Math.random() * 5 + 0.1;
      }
    }

    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferSubData(gl.ARRAY_BUFFER, 0, positions);

    gl.bindBuffer(gl.ARRAY_BUFFER, alphaBuffer);
    gl.bufferSubData(gl.ARRAY_BUFFER, 0, alphas);
  }

  function preloadStarfield() {
    const simulatedTime = 5;
    const steps = 60;
    const deltaTime = simulatedTime / steps;

    for (let i = 0; i < steps; i++) {
      updatePositions(deltaTime, false); // Don't fade in stars during preload
    }
  }

  function drawScene(currentTime) {
    currentTime *= 0.001;  // Convert to seconds
    const deltaTime = currentTime - lastTime;
    lastTime = currentTime;

    resizeCanvas();
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    updatePositions(deltaTime, true); // Fade in new stars during normal operation

    gl.useProgram(program);

    gl.enableVertexAttribArray(positionAttributeLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.vertexAttribPointer(positionAttributeLocation, 3, gl.FLOAT, false, 0, 0);

    gl.enableVertexAttribArray(sizeAttributeLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, sizeBuffer);
    gl.vertexAttribPointer(sizeAttributeLocation, 1, gl.FLOAT, false, 0, 0);

    gl.enableVertexAttribArray(alphaAttributeLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, alphaBuffer);
    gl.vertexAttribPointer(alphaAttributeLocation, 1, gl.FLOAT, false, 0, 0);

    const aspectRatio = canvas.height / canvas.width;
    gl.uniform1f(u_aspectRatioLocation, aspectRatio);
    gl.uniform2f(u_originLocation, originX, originY);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.drawArrays(gl.POINTS, 0, starCount);

    gl.disable(gl.BLEND);

    requestAnimationFrame(drawScene);
  }

  gl.clearColor(0, 0, 0, 1);
  resizeCanvas();
  preloadStarfield();
  requestAnimationFrame(drawScene);
}