// PrivacyContent.js
import React from "react";

const PrivacyContent = () => (
  <div>
    <h1>Privacy Policy for Hanch.net: Legal Proclamations and Reassurance of Love for You</h1>
<p><strong>Effective Date:</strong> 7/1/2024</p>

<h2>Introduction</h2>
<p>Greetings, internet traveler! Welcome to Hanch.net ("we," "us," "our," “the app”, “the site”, or "that website you stumbled upon at 2 AM"). We value your privacy more than a monkey values bananas. This Privacy Policy explains how we collect, use, and safeguard your personal information when you visit our website and use our services. Strap yourself in and buckle up for a ride through the thrilling world of data protection!</p>

<h2>Information We Collect (Because We're Nosy)</h2>
<h3>Personal Information:</h3>
<ul>
    <li><strong>Account Registration:</strong> Your name, email address, and password. If you use “Password123” we will judge you. We recommend using a totally unique password that you don’t use on any other site or any other planet.</li>
    <li><strong>Profile Information:</strong> Your display name, profile picture, and other tidbits you decide to share. Go ahead, tell us your favorite exotic fruit. We won't tell anyone (except maybe other exotic fruit enthusiasts).</li>
    <li><strong>Usage Information:</strong> We keep track of when you log in and what you do when you’re here. Your activity helps inform trends which can affect the future direction of the site.</li>
</ul>
<h3>Technical Information:</h3>
<ul>
    <li><strong>Cookies:</strong> No, not the delicious kind. Though their functionality is quite delicious. Storing cookies lets us remember your login state and other preferences. These are healthy first-party cookies that help improve your user experience, unlike those stale third-party cookies.</li>
    <li><strong>Device Information:</strong> We might know what kind of device you're using. If it's a potato with wires, we're impressed you made it this far.</li>
</ul>
<h3>Communication Information:</h3>
<ul>
    <li><strong>Customer Support:</strong> When you reach out to us, we keep a record. It's like saving love letters, but instead of sonnets, it's complaints about why the button won't click.</li>
</ul>

<h2>How We Use Your Information (Not for World Domination)</h2>
<h3>Service Provision:</h3>
<ul>
    <li>We use your info to make our site work. Shocking.</li>
    <li>We'll send you updates about our services. Don't worry, we won't spam you with "Hot Singles in Your Area" emails.</li>
</ul>
<h3>Personalization:</h3>
<ul>
    <li>We remember your preferences. You're welcome.</li>
</ul>
<h3>Security and Compliance:</h3>
<ul>
    <li>We keep things secure and legal. It's not as exciting as it sounds in the movies, but it's honest work.</li>
</ul>

<h2>Sharing Your Information (We're Not Gossips, We Swear)</h2>
<p>We don't sell your info to sketchy characters in dark alleys. However, we might share it with:</p>
<h3>Service Providers:</h3>
<ul>
    <li>Third-parties technologies that we rely on for the functionality of the site.</li>
</ul>
<h3>Legal Requirements:</h3>
<ul>
    <li>If the law comes knocking, we might have to spill the beans. We'll do it reluctantly.</li>
</ul>

<h2>Data Security (Fort Knox, But for Your Data)</h2>
<p>We protect your info like a dragon guards its gold. Minus the fire-breathing, of course. That would be a workplace hazard.</p>

<h2>Your Rights (Yes, You Have Some!)</h2>
<ul>
    <li><strong>Access and Update:</strong> You can change your info anytime. Go wild, reinvent yourself!</li>
    <li><strong>Delete Account:</strong> If you want out, we'll show you the door. No hard feelings, we promise.</li>
    <li><strong>Opt-Out:</strong> Don't want our emails? You can unsubscribe faster than you can say "Why did I sign up for this in the first place?"</li>
</ul>

<h2>Changes to This Privacy Policy (Because Change is the Only Constant)</h2>
<p>We might update this policy from time to time. We'll let you know when we do, but don't expect a parade or anything.</p>

<h2>Usage</h2>

<p>Remember, by using our site, you're agreeing to this policy. It's your pinky promise, except there's more legal weight and less playground cred.</p>
  </div>
);

export default PrivacyContent;