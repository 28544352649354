import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const Modal = ({ isOpen, onClose, title, children, type }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const modalClass = type === 'alert' ? 'modal-alert' : type === 'banner' ? 'modal-banner' : 'modal-window';
  const headerClass = type === 'alert' ? 'modal-alert-header' : type === 'banner' ? 'modal-banner-header' : 'modal-window-header';

  return (
    <div className={type === 'banner' ? '' : 'modal-overlay'} onClick={type === 'banner' ? null : onClose}>
      <div className={modalClass} onClick={(e) => e.stopPropagation()}>
        <div className={headerClass}>
          {title && <div className="modal-title">{title}</div>}
          <div className="modal-close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['window', 'alert', 'banner'])
};

Modal.defaultProps = {
  type: 'window',
  title: ''
};

export default Modal;
