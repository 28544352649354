import React, { useState, useRef } from 'react';
import defaultProfilePic from './assets/images/default-profile-pic.png';
import AdjustPicture from './AdjustPicture';
import useProfilePicture from './useProfilePicture';

const ProfilePicture = ({ currentUser }) => {
  const { photoURL, uploading, loading, saveProfilePicture } = useProfilePicture(currentUser, defaultProfilePic);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleSaveAdjustedPicture = async (blob) => {
    await saveProfilePicture(blob);
    setSelectedFile(null);
  };

  const handleCancel = () => {
    setSelectedFile(null);
  };

  return (
    <div className="profile-picture-upload">
      {selectedFile ? (
        <AdjustPicture
          imageFile={selectedFile}
          onSave={handleSaveAdjustedPicture}
          onCancel={handleCancel}
        />
      ) : (
        <>
          {loading ? (
            <div className="profile-picture-placeholder"></div>
          ) : (
            <img src={photoURL} alt="Profile" className="profile-picture" onClick={handleUploadClick} />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
        </>
      )}
      {uploading && <p>Uploading...</p>}
    </div>
  );
};

export default ProfilePicture;
