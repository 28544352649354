import { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, dbHanchUsers } from './firebase';

const useAuthState = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const logout = async () => {
    try {
      const response = await fetch('/auth/logout', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        await signOut(auth);
        console.log('User logged out');
        setUser(null); // Ensure user is set to null on logout
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        console.log('User is signed in:', firebaseUser);
        try {
          const userRef = doc(dbHanchUsers, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userRef);
          if (!userDoc.exists()) {
            await setDoc(userRef, {
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              displayName: firebaseUser.displayName || firebaseUser.email,
              createdAt: new Date(),
              lastLogin: new Date(),
              level: 'user',
            });
            console.log('User document created in Firestore');
          } else {
            const userData = userDoc.data();
            const updates = { lastLogin: new Date() };

            if (!userData.displayName || (userData.displayName.includes('@') && userData.displayName !== firebaseUser.email)) {
              updates.displayName = firebaseUser.displayName || firebaseUser.email;
            }

            if (userData.email !== firebaseUser.email) {
              updates.email = firebaseUser.email;
            }

            if (Object.keys(updates).length > 1) { // More than just lastLogin
              await updateDoc(userRef, updates);
              console.log('User document updated in Firestore');
            }
          }
          setUser(firebaseUser);
        } catch (error) {
          console.error('Error updating Firestore:', error);
          setUser(null);
        }
      } else {
        console.log('User is signed out');
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { user, loading, logout };
};

export default useAuthState;
