const invalidDisplayNames = [
  // Administrative and Reserved Names
  "Admin", "Administrator", "Moderator", "System", "Hanchnet",

  // Specific Names (e.g., User's personal names)
  "Josh Hanchar", "Josh J Hanchar", "Joshua Hanchar", "Joshua James Hanchar", "Joshua J Hanchar", "SkitzZero", "Hanch",

  // Vulgar and Offensive Names
  "Asshole", "Bastard", "Bitch", "Cunt", "Dick", "Douche", "Fag", "Fuck", "Motherfucker", "Nigger", "Piss", "Prick", "Pussy", "Shit", "Slut", "Twat", "Wanker", "Thot", "Hoe",
  "Ass", "Butt", "Butthead", "Chode", "Dipshit", "Dumb", "Jackass", "Jerk", "Nutsack", "Scumbag", "Shithead", "Smartass", "Sonofabitch",

  // Hate Speech and Discriminatory Terms
  "Nazi", "Hitler", "KKK", "Ku Klux Klan", "White Power", "Heil Hitler", "Aryan", "Gas the Jews", "Racewar", "Jew Killer", "Skinhead", "Holocaust Denier", "White Supremacist",
  "Fascist", "Hitler Did Nothing Wrong", "Islamophobe", "Jungle Bunny", "Klan Member", "Lynch", "Negrophobe", "Racist", "Supremacist", "Uncle Tom",
  "Chink", "Cholo", "Coon", "Cracker", "Dyke", "Gook", "Gypo", "He-She", "Homo", "Jap", "Jihadi", "Kike", "Lesbo", "Nigga", "Paki", "Pedo", "Retard", "Spic", "Spook", "Tranny", "Wetback",
  "NaziSympathizer", "EthnicCleansing", "HateCrime", "Genocide", "AntiSemitic", "HomoHater", "GenderHater", "RaceHater",

  // Sexually Explicit Terms
  "Porn", "Sex", "Whore", "Cock", "Dildo", "Cum", "Anal", "Blowjob", "Handjob", "Gangbang", "Rape", "Tits", "Boobs", "Buttplug", "Vibrator", "Nipple", "Orgasm",
  "Ball Licker", "Bareback", "Beaver", "Bimbo", "Blue Waffle", "Boner", "Cameltoe", "Carpet Muncher", "Choad", "Cream Pie", "Deepthroat", "Facial", "Fingerbang", "Fisting", "Fluffer", "G-Spot", "Glory Hole", "Hummer", "MILF", "Muff", "Pearl Necklace", "Pecker", "Rimjob", "Schlong", "Scrotum", "Semen", "Shag", "Snatch", "Sodomy", "Spunk", "Titty", "Wank", "Weiner", "Weenie", "Weiney",
  "Buttsex", "Cockring", "Cunnilingus", "DoublePenetration", "Erotic", "Fellatio", "Foreplay", "Intercourse", "Masturbate", "Nudity", "Pornographic", "Prostitute", "SexualIntercourse", "Sextoy", "Stripper", "Teabagging", "Threesome", "Titfuck", "Vaginal", "Voyeur", "XXX",

  // Offensive Phrases
  "I Hate", "Kill", "Die", "Murder", "Terrorist", "ISIS", "Al Qaeda", "Bomb", "Death to", "Go Die", "Suicide", "Execute", "Behead",
  "Death Wish", "Drop Dead", "Eat Shit", "F-Off", "F-U", "Go to Hell", "Hell Bound", "Hellraiser", "Homicide", "Hunt You Down", "Kill Yourself", "Maniac", "Massacre", "Psycho", "Serial Killer", "Shoot Up", "Terminate",
  "Bloodlust", "DeathDealer", "Executioner", "Gunman", "Killer", "Lunatic", "Madman", "Ripper", "Slayer", "Sniper", "Torturer", "Violence",

  // Miscellaneous Inappropriate Names
  "Drunk", "Junkie", "Stoner", "Crackhead", "Methhead", "Pothead", "Addict", "Dealer", "Pimp", "Prostitute", "Suck", "Blow", "Stink", "Lame", "Moron", "Loser", "Idiot", "Doofus", "Stupid", "Useless", "Pathetic", "Worthless",
  "Abuser", "Anarchist", "Bad Influence", "Corrupt", "Crook", "Deviant", "Gangster", "Hooligan", "Lawbreaker", "Menace", "Outlaw", "Rebel", "Scammer", "Sinner", "Thief", "Thug", "Troublemaker", "Vandal",
  "Alcoholic", "Barfly", "Boozer", "Dopehead", "DrugDealer", "DrugUser", "Felon", "Gambler", "GangBanger", "Hustler", "Inmate", "Jailbird", "Lush", "Mobster", "Outcast", "Parolee", "Racketeer", "Ruffian", "Smuggler", "Trespasser"
];

export default invalidDisplayNames;
