import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, dbHanchUsers, functions } from './firebase';
import { useNavigate } from 'react-router-dom';
import {
  signOut,
  updateProfile,
  verifyBeforeUpdateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import DashboardWelcome from './DashboardWelcome';
import DashboardHome from './DashboardHome';
import StarfieldBackground from './StarfieldBackground';
import { httpsCallable } from 'firebase/functions';
import EditableField from './EditableField';
import ProfilePicture from './ProfilePicture';
import invalidDisplayNames from './invalidDisplayNames';
import logo from './assets/images/hanch-net-logo.svg';
import Modal from './Modal';
import PasswordField from './PasswordField';

import './App.css';

const Dashboard = () => {
  const { currentUser, loading } = useAuth();
  const [userData, setUserData] = useState(null);
  const [displayNameMessage, setDisplayNameMessage] = useState({});
  const [emailMessage, setEmailMessage] = useState({});
  const [pendingEmail, setPendingEmail] = useState(null);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [showPasswordToDelete, setShowPasswordToDelete] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteInput, setDeleteInput] = useState('');
  const [password, setPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState(null);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [seenWelcome, setSeenWelcome] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Add state for password visibility
  const navigate = useNavigate();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

  useEffect(() => {
    document.title = "Hanch.net Account";
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(dbHanchUsers, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();

            if (data.pendingEmail && data.pendingEmail === currentUser.email) {
              await updateProfile(currentUser, { displayName: currentUser.email });
              await updateDoc(userDocRef, {
                displayName: currentUser.email,
                pendingEmail: null,
              });
              data.displayName = currentUser.email;
              setEmailMessage({ message: 'Email has been successfully updated!', type: 'success' });
              setPendingEmail(null);
            } else {
              setPendingEmail(data.pendingEmail || null);
            }

            // Apply level appearance based on user data
            applyLevelAppearance(data.level);
          } else {
            setError("No user data found.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError({ message: "Failed to load user data. Please try again.", type: 'important' });
        }
      }
    };

    fetchUserData();
  }, [currentUser, navigate]);

  const handleLogout = async () => {
    try {
      if (!isLocalhost) {
        // Call the server-side logout endpoint
        const response = await fetch('https://account.hanch.net/auth/logout', {
          method: 'POST',
          credentials: 'include', // Ensure cookies are sent with the request
        });

        if (!response.ok) {
          throw new Error('Server-side logout failed');
        }
      } else {
        console.log('Running on localhost, skipping server-side logout');
      }

      // Perform client-side logout
      await signOut(auth);

      console.log("Logout successful, navigating to login page");

      // Navigate to login page with logout information
      navigate('/login', { 
        state: { 
          logoutMessage: 'You have been successfully logged out.',
          cookieCleared: !isLocalhost
        },
        replace: true
      });
    } catch (error) {
      console.error("Error logging out:", error);
      setError({ message: "Failed to log out. Please try again.", type: 'important' });
    }
  };

  const handleContinue = async () => {
    setSeenWelcome(true);
  };

  const handleJoinClub = async () => {
    if (userData.displayName === currentUser.email) {
      setDisplayNameMessage({ message: 'You need a name to join the club!', type: 'important' });
    } else {
      if (currentUser) {
        const userDocRef = doc(dbHanchUsers, 'users', currentUser.uid);
        await updateDoc(userDocRef, { level: 'member' });
        setUserData((prevData) => ({
          ...prevData,
          level: 'member'
        }));
        applyLevelAppearance('member');
      }
    }
  };

  const handleRevokeMembership = async () => {
    if (currentUser) {
      const userDocRef = doc(dbHanchUsers, 'users', currentUser.uid);
      await updateDoc(userDocRef, { level: 'user' });
      setUserData((prevData) => ({
        ...prevData,
        level: 'user'
      }));
      applyLevelAppearance('user');
    }
  };

  const checkDisplayNameExists = async (displayName) => {
    try {
      const checkDisplayName = httpsCallable(functions, 'checkDisplayName');
      const result = await checkDisplayName({ displayName });
      return result.data.exists;
    } catch (error) {
      console.error('Error checking display name:', error);
      throw error;
    }
  };

  const isValidDisplayName = (name) => {
    const normalizedLowerCaseName = name.toLowerCase().replace(/\s+/g, '');

    if (name.length < 4 || name.length > 20) {
      return { message: 'Name must be between 4-20 characters.', type: 'important' };
    }
    if (!/^[a-zA-Z0-9 ]+$/.test(name)) {
      return { message: 'Name can only contain numbers, letters, and spaces.', type: 'important' };
    }
    if (name.startsWith(' ') || name.endsWith(' ') || name.includes('  ')) {
      return { message: 'Name cannot start with a space, end with a space, or contain consecutive spaces.', type: 'important' };
    }
    const spaceCount = (name.match(/ /g) || []).length;
    if (spaceCount > 2) {
      return { message: 'Name can contain a maximum of two spaces.', type: 'important' };
    }

    for (const invalidName of invalidDisplayNames) {
      const normalizedInvalidName = invalidName.toLowerCase().replace(/\s+/g, '');
      if (normalizedLowerCaseName.includes(normalizedInvalidName)) {
        return { message: 'Name not allowed.', type: 'important' };
      }
    }

    return null;
  };

const handleSave = async (field, value) => {
  try {
    const userDocRef = doc(dbHanchUsers, 'users', currentUser.uid);

    if (field === 'displayName') {
      setIsUpdatingName(true);
      setDisplayNameMessage({ message: 'Updating name...', type: 'info' });

      const errorMessage = isValidDisplayName(value);
      if (errorMessage) {
        setDisplayNameMessage(errorMessage);
        setIsUpdatingName(false);
        return;
      }

      const displayNameExists = await checkDisplayNameExists(value);
      if (displayNameExists) {
        setDisplayNameMessage({ message: 'Name is already in use. Please choose another.', type: 'important' });
        setIsUpdatingName(false);
        return;
      }

      await updateProfile(currentUser, { displayName: value });
      await updateDoc(userDocRef, { displayName: value });
      setUserData((prevData) => ({
        ...prevData,
        displayName: value
      }));
      setDisplayNameMessage({ message: 'Name updated successfully!', type: 'success' });
    } else if (field === 'email') {
      if (value === currentUser.email) {
        setEmailMessage({ message: 'This is already your current email address.', type: 'info' });
        return;
      }
      setNewEmail(value);
      setShowPasswordPrompt(true);
    }

    console.log(`${field} update initiated successfully`);
  } catch (error) {
    console.error(`Error updating ${field}:`, error);
    if (field === 'displayName') {
      setDisplayNameMessage({ message: `Error updating ${field}: ${error.message}`, type: 'important' });
    } else if (field === 'email') {
      setEmailMessage({ message: `Error updating ${field}: ${error.message}`, type: 'important' });
    }
  } finally {
    if (field === 'displayName') {
      setIsUpdatingName(false);
    }
  }
};

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const credential = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, credential);

      await verifyBeforeUpdateEmail(currentUser, newEmail);
      const userDocRef = doc(dbHanchUsers, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        pendingEmail: newEmail
      });
      setPendingEmail(newEmail);
      setEmailMessage({ message: `Verification email sent to ${newEmail}. Click the link inside to verify the change`, type: 'info' });

      setShowPasswordPrompt(false);
      setPassword('');
      setNewEmail('');
    } catch (error) {
      console.error('Error re-authenticating:', error);
      setEmailMessage({ message: `Error: ${error.message}`, type: 'important' });
    }
  };

  const handleResendVerification = async () => {
    try {
      await verifyBeforeUpdateEmail(currentUser, pendingEmail);
      setEmailMessage({ message: 'Verification email resent. Please check your email.', type: 'info' });
    } catch (error) {
      console.error('Error resending verification email:', error);
      setEmailMessage({ message: `Error resending verification email: ${error.message}`, type: 'important' });
    }
  };

  const handleCancelEmailChange = async () => {
    try {
      const userDocRef = doc(dbHanchUsers, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        pendingEmail: null
      });
      setPendingEmail(null);
      setEmailMessage({ message: 'Email change cancelled.', type: 'info' });
    } catch (error) {
      console.error('Error cancelling email change:', error);
      setEmailMessage({ message: `Error cancelling email change: ${error.message}`, type: 'important' });
    }
  };

  const handleCancelEmailPrompt = () => {
    setShowPasswordPrompt(false);
    setPassword('');
    setNewEmail('');
    setEmailMessage({});
  };
  const clearDisplayNameMessage = () => setDisplayNameMessage({});
  const clearEmailMessage = () => setEmailMessage({});

  const applyLevelAppearance = (level) => {
    const root = document.documentElement;
    switch (level?.toLowerCase()) {
      case 'user':
        root.style.setProperty('--color-level-window', 'rgba(51, 51, 51, 0.5)');
        break;
      case 'member':
      case 'elite':
        root.style.setProperty('--color-level-window', 'rgba(51, 51, 102, 0.5)');
        break;
      default:
        root.style.setProperty('--color-level-window', 'rgba(51, 51, 51, 0.5)');
        break;
    }
  };

  const getLevelText = (level) => {
    switch (level?.toLowerCase()) {
      case 'user':
        return 'Hanch.net User';
      case 'member':
        return 'Hanch.net Star Club';
      case 'elite':
        return 'Hanch.net Star Club Elite';
      default:
        return 'Unknown Level';
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <div>Access Denied</div>;
  }

  const handleOpenDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(true);
  };

  const handleCloseDeleteAccountModal = () => {
    setShowPasswordToDelete(false);
    setShowPassword(false);
    setIsDeleteAccountModalOpen(false);
    setDeleteConfirmation(false);
    setDeleteInput('');
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    if (!showPasswordToDelete) {
      setShowPasswordToDelete(true);
      return;
    }
    try {
      const credential = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, credential);

      // Implement account deletion logic here
      console.log("Delete account functionality to be implemented");

      setShowPasswordToDelete(false);
      setShowPassword(false);
      setDeleteConfirmation(true);

    } catch (error) {
      console.error('Error re-authenticating:', error);
      setError({ message: `Error: ${error.message}`, type: 'important' });
    }
  };

  return (
    <div className="dashboard">
      {(userData?.level?.toLowerCase() === 'member' || userData?.level?.toLowerCase() === 'elite') && <StarfieldBackground />}
      {seenWelcome ? (
        <DashboardHome
          setSeenWelcome={setSeenWelcome}
        />
      ) : (
        <DashboardWelcome
          getLevelText={getLevelText}
          userData={userData}
          onContinue={handleContinue}
          currentUser={currentUser}
          ProfilePicture={ProfilePicture}
          EditableField={EditableField}
          handleSave={handleSave}
          handleLogout={handleLogout}
          isUpdatingName={isUpdatingName}
          displayNameMessage={displayNameMessage}
          clearDisplayNameMessage={clearDisplayNameMessage}
          emailMessage={emailMessage}
          showPasswordPrompt={showPasswordPrompt}
          handlePasswordSubmit={handlePasswordSubmit}
          handleCancelEmailPrompt={handleCancelEmailPrompt}
          password={password}
          setPassword={setPassword}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          pendingEmail={pendingEmail}
          handleResendVerification={handleResendVerification}
          handleCancelEmailChange={handleCancelEmailChange}
          clearEmailMessage={clearEmailMessage}
          setUserData={setUserData}
          logo={logo}
          handleJoinClub={handleJoinClub}
          handleRevokeMembership={handleRevokeMembership}
          handleOpenDeleteAccountModal={handleOpenDeleteAccountModal}
        />
      )}
      <Modal
        isOpen={isDeleteAccountModalOpen}
        onClose={handleCloseDeleteAccountModal}
        title="Delete Account Permanently"
        type="alert"
      >
        <div className="modal-alert-content">
          Wait, seriously? You realize that this is a forever move, right? All your settings and custom content - gone forever. You sure?
          {showPasswordToDelete && (
            <form className="small-form" onSubmit={handleDeleteAccount}>
              <PasswordField
                password={password}
                setPassword={setPassword}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
              {error && <div className="error-message">{error.message}</div>}
            </form>
          )}
          {deleteConfirmation && (
            <form className="small-form" onSubmit={handleDeleteAccount}>
              <input
                type="text"
                placeholder="Type the word DELETE"
                value={deleteInput}
                onChange={(e) => setDeleteInput(e.target.value)}
              />
              {error && <div className="error-message">{error.message}</div>}
            </form>
          )}
          <div className="buttonrow">
            <button type="button" onClick={handleCloseDeleteAccountModal}>Cancel</button>
            <button
              type="button"
              disabled={deleteConfirmation && deleteInput !== 'DELETE'}
              style={{ backgroundColor: 'red', color: 'white', opacity: deleteConfirmation && deleteInput !== 'DELETE' ? 0.25 : 1 }}
              onClick={handleDeleteAccount}
            >
              {!showPasswordToDelete && !deleteConfirmation ? 'Delete' : showPasswordToDelete && !deleteConfirmation ? 'Authorize' : 'Delete it!'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
