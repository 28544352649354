import React, { useEffect, useState } from "react";
import StarClubLogo from './assets/images/StarClubLogo';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, dbHanchUsers } from './firebase';

const DashboardHome = ({ setSeenWelcome }) => {
  const [mailingList, setMailingList] = useState(false);

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(dbHanchUsers, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const notifications = userDocSnap.data().notifications?.starClub;
            if (notifications) {
              setMailingList(notifications.email || false);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching notification settings:", error);
      }
    };

    fetchNotificationSettings();
  }, []);

  const handleMailingListChange = async (e) => {
    const newValue = e.target.checked;
    setMailingList(newValue);

    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(dbHanchUsers, 'users', user.uid);
        await updateDoc(userDocRef, {
          'notifications.starClub.email': newValue
        });
      }
    } catch (error) {
      console.error("Error updating mailing list:", error);
    }
  };

  return (
    <div className="scrollable-page">
      <div className="welcome-content">
        <StarClubLogo />
        <div>It's a long flight. Sit back and relax.</div>
        <div className="welcome-container">
          <h2>Stay in the loop</h2>
          <label className="custom-checkbox spread">
            Email subscription
            <input
              type="checkbox"
              checked={mailingList}
              onChange={handleMailingListChange}
            />
          </label>
        </div>
        <div className="buttonrow">
          <button onClick={() => setSeenWelcome(false)}>Go back</button>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
