// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div className="loading"><FontAwesomeIcon icon={faSpinner} spinPulse /></div>; // Show a loading state while authentication is being checked
  }

  return currentUser ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
