import React, { useState } from 'react';
import StarClubLogo from './assets/images/StarClubLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PasswordField from './PasswordField'; // Import PasswordField component

const DashboardWelcome = ({
  handleLogout, getLevelText, userData, onContinue, currentUser,
  ProfilePicture, EditableField, handleSave, isUpdatingName, displayNameMessage,
  clearDisplayNameMessage, emailMessage, showPasswordPrompt, handlePasswordSubmit,
  handleCancelEmailPrompt, password, setPassword, newEmail, setNewEmail, pendingEmail,
  handleResendVerification, handleCancelEmailChange, clearEmailMessage, setUserData, logo,
  handleJoinClub, handleRevokeMembership, handleOpenDeleteAccountModal
}) => {
  const [showPassword, setShowPassword] = useState(false); // Define the state for password visibility

  const getWelcomeText = (level) => {
    switch (level?.toLowerCase()) {
      case 'user':
        return (
          <>
            Welcome to
            <br />
            <img src={logo} className="logo" alt="Hanch Net Logo" />
          </>
        );
      case 'member':
        return (
          <>
            Welcome to the
            <br />
            <StarClubLogo />
          </>
        );
      case 'elite':
        return (
          <>
            Welcome to the
            <br />
            ⭐️ Star Club Elite
          </>
        );
      default:
        return 'Welcome';
    }
  };

  return (
    <div className="scrollable-page">
      {userData ? (
        <div className="welcome-content">
          <h1>{getWelcomeText(userData?.level)}</h1>
          <div className="welcome-container">
            <ProfilePicture currentUser={currentUser} userData={userData} onUpdateUserData={setUserData} />
            <div>
              <h2>Name</h2>
              <EditableField
                initialValue={userData.displayName === currentUser.email ? '' : userData.displayName || ''}
                onSave={(value) => handleSave('displayName', value)}
                placeholder={userData.displayName === currentUser.email ? 'Enter a unique name' : 'Enter Your Name'}
                isUpdating={isUpdatingName}
                alertMessage={displayNameMessage}
                onClearAlert={clearDisplayNameMessage}
              />
            </div>
            <div>
              <h2>Email</h2>
              {!showPasswordPrompt && (
                <EditableField
                  initialValue={currentUser.email}
                  onSave={(value) => handleSave('email', value)}
                  alertMessage={emailMessage}
                  onClearAlert={clearEmailMessage}
                />
              )}
              {showPasswordPrompt && (
                <form onSubmit={handlePasswordSubmit}>
                  <br />
                  <PasswordField 
                    password={password}
                    setPassword={setPassword}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                  <br />
                  <div className="buttonrow">
                    <button type="button" onClick={handleCancelEmailPrompt}>Cancel</button>
                    <button type="submit">Change Email</button>
                  </div>
                </form>
              )}
            </div>
            {pendingEmail && (
              <div>
                <div className="buttonrow">
                  <button onClick={handleResendVerification}>Resend Verification</button>
                  <button onClick={handleCancelEmailChange}>Cancel Change</button>
                </div>
              </div>
            )}
          </div>
          <div className="welcome-container">
            <div>
              <h2>Access Level</h2>
              <div className="column-tight">
                {getLevelText(userData.level)}
                {userData.level === 'member' && (
                <div className="textlink small" onClick={handleRevokeMembership}>Downgrade</div>
                )}
              </div>
            </div>
            {userData.level === 'user' ? (
              <div className="column-tight">
                <button onClick={handleJoinClub}>Join the Star Club</button>
              </div>
            ) : (
              <button onClick={onContinue}>Continue</button>
            )}
          </div>            
          <span className="textlink" onClick={handleLogout}>Logout</span>
          <span className="textlink subtle small" onClick={handleOpenDeleteAccountModal}>Delete Account</span>
        </div>
      ) : (
        <div className="loading"><FontAwesomeIcon icon={faSpinner} spinPulse /></div>
      )}
    </div>
  );
};

export default DashboardWelcome;
