// TermsContent.js
import React from "react";

const TermsContent = () => (
  <div>
<h1>Terms of Use for Hanch.net</h1>
<p><strong>Effective Date:</strong> 7/1/2024</p>

<h2>Introduction</h2>
<p>Welcome to Hanch.net ("we," "us," "our," “the app”, “the site”, or "the digital playground"). By using our site, you're agreeing to these terms. It's kind of like shaking hands, but with more pixels.</p>

<h2>Use of Our Site</h2>
<p>By visiting and using Hanch.net, you agree to the following:</p>
<ul>
    <li><strong>Play Nice:</strong> Treat others with respect. No trolling, spamming, or general internet nastiness.</li>
    <li><strong>Content Ownership:</strong> The content on this site is ours (unless it says otherwise). Don’t steal it. If you want to use it, ask first.</li>
    <li><strong>Your Content:</strong> Anything you post here remains yours, but you give us permission to use it. We promise not to use it in any weird ways.</li>
    <li><strong>No Funny Business:</strong> Don’t use our site for any illegal or unauthorized purposes. We’re here to have fun, not to break the law.</li>
</ul>

<h2>Account Responsibility</h2>
<ul>
    <li><strong>Keep It Secure:</strong> You’re responsible for keeping your account secure. If your cat logs in and deletes everything, that’s on you.</li>
    <li><strong>Accurate Info:</strong> Provide accurate information. We’re not saying you can’t be a secret agent, but we need some basic truths.</li>
</ul>

<h2>Disclaimers</h2>
<ul>
    <li><strong>No Guarantees:</strong> We strive for awesomeness, but we can’t guarantee our site will always be up, bug-free, or perfect. Use it as-is.</li>
    <li><strong>Not Liable:</strong> We’re not liable for any damages or losses related to your use of the site. This is our way of saying, "Use at your own risk."</li>
</ul>

<h2>Changes to These Terms</h2>
<p>We might update these terms occasionally. When we do, we’ll let you know. Think of it as an evolving handshake agreement.</p>

<h2>Usage</h2>

<p>By using our site, you're agreeing to these terms. It's like a pinky promise, but with more legal weight and fewer playground antics.</p>
  </div>
);

export default TermsContent;