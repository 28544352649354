import React, { useEffect, useRef } from 'react';
import { initializeStarfield } from './starfield'; // Adjust the path if necessary

const StarfieldBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      initializeStarfield(canvasRef.current);
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1
      }}
    ></canvas>
  );
};

export default StarfieldBackground;
