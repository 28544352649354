import { useEffect } from 'react';

const useFocusOut = (ref, onFocusOut) => {
  useEffect(() => {
    const handleFocusOut = (event) => {
      if (ref.current && !ref.current.contains(event.relatedTarget)) {
        onFocusOut();
      }
    };

    document.addEventListener('focusout', handleFocusOut);

    return () => {
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, [ref, onFocusOut]);
};

export default useFocusOut;
