import React from 'react';

const StarClubLogoSVG = `

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" version="1.2" viewBox="0 0 133.6 27.4">
  <g>
    <path d="M0,17.5h3.2c.6,0,1,0,1.2,0,.5,0,.7-.3.7-.7s-.4-.7-1.3-1.4c-1.2-.9-2-1.6-2.4-2.1-.9-1-1.3-2-1.3-3,0-3.1,1.9-4.6,5.7-4.6h4.8v4.7h-3.2c-1,0-1.5.2-1.5.6s.5.7,1.4,1.4c1.2.9,2.1,1.6,2.6,2.2.9,1.1,1.4,2.2,1.4,3.3s-.5,2.5-1.6,3.3c-1.1.9-2.6,1.3-4.5,1.3H0v-4.8Z" fill="currentColor" stroke-width="0"/>
    <path d="M11.4,10.3v-4.8h10.8v4.8h-2.8v12h-5.3v-12h-2.8Z" fill="currentColor" stroke-width="0"/>
    <path d="M28.8,17.1v-4.3h1.6v-1.5c0-.9-.4-1.4-1.2-1.4s-1.2.5-1.2,1.4v10.9h-5.3v-10.8c0-1.5.5-2.8,1.4-4.1,1.2-1.5,2.9-2.3,5-2.3s3.8.8,5,2.3c1,1.2,1.4,2.6,1.4,4.1v10.8h-5.3v-5.1h-1.6Z" fill="currentColor" stroke-width="0"/>
    <path d="M43.1,22.3h-5.3v-10.6c0-2.1.6-3.7,1.7-4.8s2.7-1.7,4.7-1.7,2.9.5,4.1,1.4c1.3,1,1.9,2.2,1.9,3.7s-.5,2.6-1.6,3.5c1.2.4,1.9,1.6,1.9,3.4v5h-5.3v-4.2c0-.6,0-1-.3-1.3-.2-.2-.6-.4-1.2-.4v-4c1.1,0,1.7-.4,1.7-1.2s-.4-1.2-1.2-1.2-.7.1-.9.3c-.2.2-.3.6-.3,1.3v10.8Z" fill="currentColor" stroke-width="0"/>
  </g>
  <g>
    <path d="M92.6,17.5v4.8h-2.8c-2.2,0-4.1-.8-5.7-2.4-1.6-1.6-2.4-3.6-2.4-6s.8-4.4,2.4-6c1.6-1.6,3.5-2.4,5.7-2.4h2.8v4.8h-1.6c-1.1,0-2,.3-2.8,1s-1.1,1.5-1.1,2.6.4,1.9,1.1,2.6c.7.7,1.7,1,2.8,1h1.6Z" fill="currentColor" stroke-width="0"/>
    <path d="M104.1,17.5v4.8h-4.6c-1.5,0-2.7-.5-3.6-1.4-.9-.9-1.4-2.1-1.4-3.6V5.5h5.3v10.4c0,.8.4,1.4,1.1,1.6.2,0,1,0,2.2,0h1Z" fill="currentColor" stroke-width="0"/>
    <path d="M105,5.5h5.3v11.1c0,.8.4,1.2,1.2,1.2s1.2-.4,1.2-1.2V5.5h5.3v11.4c0,1.7-.6,3.1-1.9,4.2-1.2,1-2.7,1.6-4.5,1.6s-3.2-.5-4.5-1.6c-1.3-1.1-1.9-2.5-1.9-4.2V5.5Z" fill="currentColor" stroke-width="0"/>
    <path d="M125.5,22.3h-5.3v-11.5c0-1.7.6-3,1.7-4.1,1.2-1,2.7-1.5,4.6-1.5s2.9.4,4.1,1.3c1.2,1,1.8,2.2,1.8,3.8s-.4,2.1-1.1,2.9c1.4.9,2.1,2.2,2.1,4.1s-.5,2.8-1.5,3.7c-1,.9-2.2,1.3-3.7,1.3h-2.2v-4.5c1.3,0,2-.5,2-1.2s-.2-.8-.7-1c-.3,0-.7-.1-1.3-.1v-3.7c.8,0,1.2-.4,1.2-1.1s-.1-.5-.3-.6c-.2-.2-.4-.2-.7-.2-.6,0-.9.4-.9,1.3v11.1Z" fill="currentColor" stroke-width="0"/>
  </g>
  <path fill="#ffd000" d="M70,9.5h9.2c1.1,0,1.6,1.4,0.7,2l-7.5,5.4l2.9,8.8c0.3,1-0.9,1.9-1.7,1.3l-7.5-5.4l-7.5,5.4c-0.9,0.6-2.1-0.2-1.7-1.3l2.9-8.8l-7.5-5.4c-0.9-0.6-0.4-2,0.7-2h9.2l2.9-8.8c0.3-1,1.8-1,2.2,0z"/>
</svg>
`;

const StarClubLogo= () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: StarClubLogoSVG }} />
  );
};

export default StarClubLogo;
