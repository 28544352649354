import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, dbHanchUsers } from "./firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence
} from "firebase/auth";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import HanchnetLogo from "./assets/images/HanchnetLogo";
import './App.css';
import Modal from "./Modal";
import PrivacyContent from "./PrivacyContent";
import TermsContent from "./TermsContent";
import PasswordField from "./PasswordField";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);
  const [isCheckingVerification, setIsCheckingVerification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [cookiesRequiredModalOpen, setCookiesRequiredModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get("returnUrl");

  const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

  useEffect(() => {
    document.title = "Hanch.net Account Login";
  }, []);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    const rememberedPassword = localStorage.getItem('rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          handleSuccessfulAuth(user);
        } else {
          setIsCheckingVerification(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isCheckingVerification) {
      const interval = setInterval(() => {
        const user = auth.currentUser;
        if (user) {
          user.reload().then(() => {
            if (user.emailVerified) {
              clearInterval(interval);
              setIsCheckingVerification(false);
              handleSuccessfulAuth(user);
            }
          });
        }
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [isCheckingVerification]);

  useEffect(() => {
    const checkCookiesEnabled = () => {
      const cookies = document.cookie.split(';');
      const cookiesEnabled = cookies.find(cookie => cookie.trim().startsWith('cookies_enabled='));
      if (!cookiesEnabled) {
        setCookiesRequiredModalOpen(true);
      }
    };

    checkCookiesEnabled();
  }, []);

  const createCookiesEnabledCookie = () => {
    document.cookie = "cookies_enabled=true; path=/; max-age=31536000"; // Expires in 1 year
    setCookiesRequiredModalOpen(false);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#]{8,}$/;
    const isValid = passwordRegex.test(password);
    console.log(`Password validation for "${password}": ${isValid}`);
    return isValid;
  };
  
const handleSuccessfulAuth = async (user) => {
  if (rememberMe) {
    localStorage.setItem('rememberedEmail', email);
    localStorage.setItem('rememberedPassword', password);
    await setPersistence(auth, browserSessionPersistence);
  } else {
    localStorage.removeItem('rememberedEmail');
    localStorage.removeItem('rememberedPassword');
  }

  try {
    // Update Firestore user document
    const userDocRef = doc(dbHanchUsers, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || user.email,
        createdAt: new Date(),
        lastLogin: new Date(),
        level: 'user',
        notifications: {
          starClub: {
            email: true,
            push: true,
          },
        },
      });
      console.log('User document created in Firestore');
    } else {
      const userData = userDoc.data();
      const updates = { lastLogin: new Date() };

      if (!userData.displayName || (userData.displayName.includes('@') && userData.displayName !== user.email)) {
        updates.displayName = user.displayName || user.email;
      }

      if (userData.email !== user.email) {
        updates.email = user.email;
      }

      if (!userData.notifications || !userData.notifications.starClub) {
        updates.notifications = {
          starClub: {
            email: true,
            push: true,
          },
        };
      } else {
        if (userData.notifications.starClub.email === undefined) {
          updates['notifications.starClub.email'] = true;
        }
        if (userData.notifications.starClub.push === undefined) {
          updates['notifications.starClub.push'] = true;
        }
      }

      // Ensure user level is set correctly
      if (!userData.level) {
        updates.level = 'user'; // Ensure the level is set if it's missing
      }

      if (Object.keys(updates).length > 1) { // More than just lastLogin
        await updateDoc(userDocRef, updates);
        console.log('User document updated in Firestore');
      }
    }

    // Set session cookie
    if (!isLocalhost) {
      const idToken = await user.getIdToken();
      const response = await fetch('/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken }),
        credentials: 'include',
      });

      const result = await response.json();
      if (response.ok && result.status === 'success') {
        console.log('Session cookie set successfully');
      } else {
        setErrorMessage('Error setting session cookie: Unexpected response');
        return;
      }
    }

    if (returnUrl) {
      window.location.href = decodeURIComponent(returnUrl);
    } else {
      navigate("/dashboard");
    }
  } catch (error) {
    console.error('Error during login:', error);
    setErrorMessage(`Error: ${error.message}`);
  }
};

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!email || !password) {
      setErrorMessage("Think you can just barge right in without identifying yourself?");
      return;
    }

    console.log(`Attempting to log in with email: ${email} and password: ${password}`);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log(`User logged in: ${user.email}`);

      if (!user.emailVerified) {
        setErrorMessage("Please verify your email before logging in.");
        await sendEmailVerification(user);
        setConfirmationMessage("A new verification email has been sent. Please check your inbox.");
        return;
      }

      handleSuccessfulAuth(user);
    } catch (error) {
      console.error("Error logging in:", error);
      handleLoginError(error);
    }
  };

  const handleLoginError = (error) => {
    console.error("Full Error Object: ", error);

    switch (error.code) {
      case 'auth/wrong-password':
        setErrorMessage('Incorrect password.');
        break;
      case 'auth/user-not-found':
        setErrorMessage('No user found with this email.');
        break;
      case 'auth/network-request-failed':
        setErrorMessage('Network error, please try again.');
        break;
      case 'auth/invalid-email':
        setErrorMessage('Invalid email address.');
        break;
      case 'auth/too-many-requests':
        setErrorMessage('Too many login attempts. Please reset your password or try again later.');
        break;
      case 'auth/invalid-credential':
        setErrorMessage('Invalid username or password.');
        break;
      default:
        setErrorMessage(`Login failed: ${error.message}`);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!email || !password) {
      setErrorMessage("Well that's not going to work. Please fill out the form.");
      return;
    }

    console.log(`Attempting to sign up with email: ${email} and password: ${password}`);

    if (!validatePassword(password)) {
      setErrorMessage("Password must be at least 8 characters long, contain at least one uppercase letter and one number.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log(`User signed up: ${user.email}`);

      const actionCodeSettings = {
        url: returnUrl || `${window.location.origin}/dashboard`,
        handleCodeInApp: true,
      };

      await sendEmailVerification(user, actionCodeSettings);
      setIsConfirmationSent(true);
      setConfirmationMessage("Confirmation email sent. Please verify.");

      const userDocRef = doc(dbHanchUsers, 'users', user.uid);
      await setDoc(userDocRef, {
        uid: user.uid,
      });

      setIsCheckingVerification(true);
    } catch (error) {
      console.error("Error creating account:", error);
      handleSignupError(error);
    }
  };

  const handleSignupError = (error) => {
    console.error("Full Error Object: ", error);

    switch (error.code) {
      case 'auth/email-already-in-use':
        setErrorMessage('Email already in use.');
        break;
      case 'auth/invalid-email':
        setErrorMessage('Invalid email address.');
        break;
      case 'auth/weak-password':
        setErrorMessage('Password is too weak.');
        break;
      default:
        setErrorMessage(`Signup failed: ${error.message}`);
    }
  };

  const handleResendVerification = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const actionCodeSettings = {
          url: returnUrl || `${window.location.origin}/dashboard`,
          handleCodeInApp: true,
        };

        await sendEmailVerification(user, actionCodeSettings);
        setConfirmationMessage("Verification email resent. Check your inbox.");
      } else {
        setErrorMessage("No user is currently logged in.");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setErrorMessage("Failed to resend verification email. Please try again.");
    }
  };

  const handlePasswordReset = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        setErrorMessage('Password reset email sent. Please check your inbox.');
      } catch (error) {
        console.error("Error during password reset:", error);
        setErrorMessage('Failed to send password reset email. Please try again.');
      }
    } else {
      setErrorMessage('Please enter your email to reset your password.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
      <HanchnetLogo/>
      {isConfirmationSent ? (
        <div className="login-confirmationsent-container">
          <p>{confirmationMessage}</p>
          <button onClick={handleResendVerification}>Resend Verification Email</button>
        </div>
      ) : (
        <form onSubmit={handleLogin}>
          <div className="login-form-container">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              autoComplete="email"
            />
            <PasswordField 
              password={password}
              setPassword={setPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <div>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                Remember me
              </label>
            </div>
          </div>
          <div className="buttonrow">
            <button type="button" onClick={handleSignup}>Join</button>
            <button type="submit">Enter</button>
          </div>
          <span className="password-link" onClick={handlePasswordReset}>
            Forgot Password?
          </span>
          <div className="login-error">
            {errorMessage && <p>{errorMessage}</p>}
          </div>
        </form>
      )}
      </div>
      <div className="disclaimer-links">
        <div className="textlink subtle" onClick={() => setModalContent("privacy")}>Privacy</div>
        <div className="textlink subtle" onClick={() => setModalContent("terms")}>Terms</div>
      </div>
      <Modal isOpen={!!modalContent} onClose={() => setModalContent(null)} title={modalContent === "privacy" ? "Privacy Policy" : "Terms of Use"}>
        {modalContent === "privacy" ? <PrivacyContent /> : <TermsContent />}
      </Modal>
      <Modal isOpen={cookiesRequiredModalOpen} onClose={() => setCookiesRequiredModalOpen(false)} title="Legally Required Fluff" type="banner">
        <div className="modal-alert-content">
          <span className="smalltext">Hanch.net uses browser cookies in order to function. By continuing to use this site, you consent to our use of cookies. Click the button below to prevent this message from appearing again on this device in this browser. For more info, check out the <span className="textlink" onClick={() => setModalContent("privacy")}>Privacy Policy.</span></span></div>
        <div className="buttonrow">
          <button onClick={createCookiesEnabledCookie}>Yeah, yeah, I accept</button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
