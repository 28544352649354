import { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { storage } from './firebase';

const useProfilePicture = (currentUser, defaultProfilePic) => {
  const [photoURL, setPhotoURL] = useState('');
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPhotoURL = async () => {
      try {
        if (currentUser.photoURL) {
          const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
          const url = await getDownloadURL(storageRef);
          setPhotoURL(url);
        } else {
          setPhotoURL(defaultProfilePic);
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
        setPhotoURL(defaultProfilePic);
      }
      setLoading(false);
    };

    fetchPhotoURL();
  }, [currentUser, defaultProfilePic]);

  const saveProfilePicture = async (blob) => {
    setUploading(true);
    try {
      const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);
      await updateProfile(currentUser, { photoURL: url });
      setPhotoURL(url);
      console.log('Profile picture updated successfully');
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
    setUploading(false);
  };

  return { photoURL, uploading, loading, saveProfilePicture };
};

export default useProfilePicture;
