import React, { useState, useRef, useEffect } from 'react';

const AdjustPicture = ({ imageFile, onSave, onCancel }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [image, setImage] = useState(null);
  const [minScale, setMinScale] = useState(1);
  const canvasRef = useRef(null);
  const frameSize = 300;
  const lastPositionRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (imageFile) {
      const img = new Image();
      img.onload = () => {
        setImage(img);
        const initialScale = calculateInitialScale(img);
        setMinScale(initialScale);
        setScale(initialScale);
      };
      img.src = URL.createObjectURL(imageFile);

      return () => {
        URL.revokeObjectURL(img.src);
      };
    }
  }, [imageFile]);

  const calculateInitialScale = (img) => {
    const scaleX = frameSize / img.width;
    const scaleY = frameSize / img.height;
    return Math.max(scaleX, scaleY);
  };

  useEffect(() => {
    if (image && canvasRef.current) {
      drawImage();
    }
  }, [scale, position, image]);

  const drawImage = () => {
    const canvas = canvasRef.current;
    if (!canvas || !image) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const scaledWidth = image.width * scale;
    const scaledHeight = image.height * scale;
    const x = (canvas.width - scaledWidth) / 2 + position.x;
    const y = (canvas.height - scaledHeight) / 2 + position.y;

    ctx.drawImage(image, x, y, scaledWidth, scaledHeight);
  };

  const handleStart = (e) => {
    setDragging(true);
    if (e.type === 'touchstart') {
      lastPositionRef.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };
    }
  };

  const handleMove = (e) => {
    if (dragging) {
      let movementX, movementY;
      if (e.type === 'mousemove') {
        movementX = e.movementX;
        movementY = e.movementY;
      } else if (e.type === 'touchmove') {
        movementX = e.touches[0].clientX - lastPositionRef.current.x;
        movementY = e.touches[0].clientY - lastPositionRef.current.y;
        lastPositionRef.current = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        };
      }

      const newX = position.x + movementX;
      const newY = position.y + movementY;
      
      const scaledWidth = image.width * scale;
      const scaledHeight = image.height * scale;
      const maxX = Math.max(0, (scaledWidth - frameSize) / 2);
      const maxY = Math.max(0, (scaledHeight - frameSize) / 2);

      setPosition({
        x: Math.max(-maxX, Math.min(maxX, newX)),
        y: Math.max(-maxY, Math.min(maxY, newY)),
      });
    }
  };

  const handleEnd = () => {
    setDragging(false);
  };

  const handleZoom = (e) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);

    const scaledWidth = image.width * newScale;
    const scaledHeight = image.height * newScale;
    const maxX = Math.max(0, (scaledWidth - frameSize) / 2);
    const maxY = Math.max(0, (scaledHeight - frameSize) / 2);

    setPosition(prevPosition => ({
      x: Math.max(-maxX, Math.min(maxX, prevPosition.x)),
      y: Math.max(-maxY, Math.min(maxY, prevPosition.y)),
    }));
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.toBlob((blob) => {
        // Generate a temporary URL for the blob
        const newPhotoURL = URL.createObjectURL(blob);
        
        // Pass both the blob and the new URL to the parent component
        onSave(blob, newPhotoURL);
      }, 'image/jpeg', 0.95);
    }
  };

  if (!imageFile) {
    return null;
  }

  return (
    <div className="adjust-picture">
      <canvas
        ref={canvasRef}
        width={frameSize}
        height={frameSize}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        style={{ cursor: dragging ? 'grabbing' : 'grab', touchAction: 'none' }}
      />
      <input
        type="range"
        min={minScale}
        max={minScale * 3}
        step={0.01}
        value={scale}
        onChange={handleZoom}
      />
      <div className="buttonrow">
        <button onClick={onCancel}>Cancel</button>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default AdjustPicture;
