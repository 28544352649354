import React, { useState, useRef, useEffect } from 'react';
import useFocusOut from './useFocusOut';

const EditableField = ({ initialValue, onSave, placeholder, errorMessage, isUpdating, alertMessage, onClearAlert }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  const handleSave = async () => {
    if (value !== initialValue) {
      try {
        await onSave(value);
        setError(''); // Clear error on successful save
      } catch (err) {
        setError(err.message);
        setIsEditing(true); // Keep editing mode on if save fails
        return;
      }
    }
    setIsEditing(false);
  };

  const handleBlur = () => {
    handleSave();
  };

  useFocusOut(inputRef, handleBlur);

  useEffect(() => {
    if (isEditing && !isUpdating) {
      inputRef.current.focus();
      inputRef.current.select(); // Auto-select the text field contents
    }
  }, [isEditing, isUpdating]);

  // Update local state when initialValue changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Update error message when it changes
  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  // Close editing mode when isUpdating becomes true
  useEffect(() => {
    if (isUpdating) {
      setIsEditing(false);
    }
  }, [isUpdating]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setValue(initialValue); // Reset to initial value on cancel
      setError(''); // Clear any error messages
    }
  };

  return (
    <div className="editable-field">
      {isEditing && !isUpdating ? (
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
      ) : (
        <span 
          onClick={() => {
            if (!isUpdating) {
              setIsEditing(true);
              if (onClearAlert) {
                onClearAlert();
              }
            }
          }} 
          className={`textlink ${isUpdating ? 'updating' : ''}`}
        >
          {initialValue || placeholder}
        </span>
      )}
      {error && <div className="error-message">{error}</div>}
      {alertMessage && !isEditing && <div className={`alert-message ${alertMessage.type || ''}`}>{alertMessage.message}</div>}
    </div>
  );
};

export default EditableField;

